import axios from 'axios'
import { useEffect, useState } from 'react'
import { Card, Pagination, Spinner } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { PageTitle, TableHeader, TableBody } from '../../StyledComponents'
import styled from 'styled-components'
import { constants } from '../../constants'

import './index.css';

const ProjectContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
`

const ProjectBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 40px 20px;
  @media screen and (max-width: 768px) {
    padding: 20px 20px 20px;
  }
`

const ProjectList = styled.div`
  flex: 1;
`

const TitleContainer = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 768px) {
    text-align: right;
    justify-content: flex-end;
  }
`

const ProjectImg = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 50%;
`

const NoProjectRow = styled.div`
	width: 100%;
	text-align: center;
	
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;

	/* Semiwhite */
	color: #EFF3FB;
`

const TableContainer = styled.div`
	padding: 20px;
	margin-bottom: 10px;
	width: 100%;
	background: #14151A;
	border-radius: 8px;
`

const Project = (props) => {
  let history = useHistory()
  const location = useLocation()
  const [projects, setProjects] = useState([])
  const [activePage, setActivePage] = useState(0)
  const [isLoading, setLoading] = useState(true);
  const numberPerPage = 100;

  const compareTVL = (a, b) => {
    const aVal = a.tvl !== undefined ? a.tvl : 0;
    const bVal = b.tvl !== undefined ? b.tvl : 0;

    if (aVal > bVal) {
      return -1;
    }
    if (aVal < bVal) {
      return 1;
    }

    return 0;
  }

  const renderPagination = () => {
    const numPages = Math.ceil(projects.length / numberPerPage);
    const pageLimit = 4;

    if (numPages <= pageLimit) {
      return (
        <>
          {[...Array(numPages).keys()].map((item, idx) => (
            <Pagination.Item
              key={idx}
              active={item === activePage}
              onClick={() => setActivePage(item)}
            >
              {item + 1}
            </Pagination.Item>
          ))}
        </>
      )
    } else {
      let totalArr = [...Array(numPages).keys()]
      let startIndex = activePage <= 2 ? 0 : activePage - 2
      if (startIndex + pageLimit > numPages) {
        startIndex = numPages - pageLimit
      }
      const targetArr = totalArr.slice(startIndex, startIndex + pageLimit)
      return (
        <>
          {activePage >= 3 && <Pagination.Ellipsis></Pagination.Ellipsis>}
          {targetArr.map((item, idx) => (
            <Pagination.Item
              key={idx}
              active={item === activePage}
              onClick={() => setActivePage(item)}
            >
              {item + 1}
            </Pagination.Item>
          ))}
          {activePage < numPages - 3 && (
            <Pagination.Ellipsis></Pagination.Ellipsis>
          )}
        </>
      )
    }
  }

  useEffect(() => {
    const updateProjectStatus = async () => {
      if (location.pathname.indexOf(props.route) < 0 && props.rank === undefined && props.partnership === undefined && props.pending === undefined) {
        return
      }
      try {
        const res = await axios.get(
          `${constants.api_url}/get-project`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            params: {
              category: props.category,
              subcategory: props.subcategory,
              partnership: props.partnership,
              isLegal: props.pending === true ? 0 : 1
            }
          },
        )

        var data = res.data;
        if (props.rank === true) {
          data = data.sort(compareTVL);
        }
        setProjects(data || [])
        setLoading(false);
      } catch (error) {
        //
        console.log(error)
      }

      setTimeout(updateProjectStatus, 1200000)
    }
    updateProjectStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const lastPage = Math.ceil(projects.length / numberPerPage) - 1;
  return (
    <ProjectContainer>
      {isLoading ? (<Spinner animation="border" variant="primary" />) : (
        <ProjectBody>
          <PageTitle>Fantom Project</PageTitle>
          <ProjectList>
            <Card style={{ backgroundColor: '#1D1D25' }}>
              <Card.Header>
                <Card.Title
                  style={{
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#EFF3FB',
                    fontSize: '32px',
                    justifyContent: 'space-between',
                    backgroundColor: ''
                  }}
                >
                  {props.rank ? 'Rank' : (props.partnership ? 'Partnership' : (props.pending === true ? 'Pending projects' : (props.subcategory !== undefined ? props.subcategory : props.category)))}&nbsp;{projects.length > 0 ? ('(' + projects.length + ')') : ''}
                </Card.Title>
              </Card.Header>
              <Card.Body className="desktopDevice">
                <TableHeader>
                  <div style={{ width: '5%', textAlign: 'center' }}>#</div>
                  <div style={{ width: '20%', textAlign: 'left' }}>Project</div>
                  <div style={{ width: '10%', textAlign: 'right' }}>Tags</div>
                  <div style={{ width: '20%', textAlign: 'center' }}>TVL</div>
                  <div style={{ width: '10%', textAlign: 'center' }}>Token</div>
                  <div style={{ width: '15%', textAlign: 'center' }}>Price</div>
                  <div style={{ width: '20%', textAlign: 'center' }}>24hr change</div>
                </TableHeader>
                {projects
                  .slice(activePage * 100, activePage * 100 + 100)
                  .map((item, index) => (
                    <TableBody
                      key={index}
                      onClick={() => {
                        if (props.pending === true) {
                          history.push(
                            `/review/${item._id}`,
                          )
                        } else {
                          history.push(
                            `/project-detail/${item._id}`,
                            { referrer: props.route, partnership: props.partnership, rank: props.rank },
                          )
                        }
                      }
                      }>
                      <div style={{ width: '5%', textAlign: 'center' }}>{activePage * numberPerPage + index + 1}</div>
                      <div style={{ width: '20%', textAlign: 'center' }}>
                        <TitleContainer>
                          <ProjectImg
                            src={item.logoFile !== '' ? `${constants.api_url}/logos/${item.logoFile}` : ''}
                          />
                          <span>{item.title}</span>
                        </TitleContainer>
                      </div>
                      {
                        props.pending !== true && 
                        <>
                          <div style={{ width: '10%', textAlign: 'right' }}>{item.subcategory !== undefined && item.subcategory !== '' ? item.subcategory : item.category}</div>
                          <div style={{ width: '20%', textAlign: 'center' }}>{item.tvl !== undefined && item.tvl !== 0 ? item.tvl.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}</div>
                          <div style={{ width: '10%', textAlign: 'center' }}>{item.cmcData !== undefined && item.cmcData.tokenData !== undefined && item.cmcData.tokenData.symbol !== undefined && item.cmcData.tokenData.symbol.toUpperCase()}</div>
                          <div style={{ width: '15%', textAlign: 'center' }}>{(item.cmcData !== undefined && item.cmcData.tokenData !== undefined && item.cmcData.tokenData.market_data !== undefined && item.cmcData.tokenData.market_data.current_price !== undefined && item.cmcData.tokenData.market_data.current_price.usd !== undefined) ? item.cmcData.tokenData.market_data.current_price.usd.toFixed(4) : ''}</div>
                          <div style={{ width: '20%', textAlign: 'center' }}>
                            {(item.cmcData !== undefined && item.cmcData.tokenData !== undefined && item.cmcData.tokenData.market_data !== undefined && item.cmcData.tokenData.market_data.price_change_24h !== undefined)
                              ? (item.cmcData.tokenData.market_data.price_change_24h && item.cmcData.tokenData.market_data.price_change_24h.toFixed(2) + '%')
                              : ''}
                          </div>
                        </>
                      }
                    </TableBody>
                  ))}
                {
                  projects.length === 0 &&
                  <TableBody>
                    <NoProjectRow>No projects</NoProjectRow>
                  </TableBody>
                }
                <Pagination className="pageDiv">
                  <Pagination.First onClick={() => setActivePage(0)} />
                  <Pagination.Prev
                    onClick={() =>
                      activePage >= 1
                        ? setActivePage(activePage - 1)
                        : setActivePage(0)
                    }
                  />
                  {renderPagination()}
                  <Pagination.Next
                    onClick={() =>
                      activePage < lastPage
                        ? setActivePage(activePage + 1)
                        : setActivePage(lastPage)
                    }
                  />
                  <Pagination.Last onClick={() => setActivePage(lastPage)} />
                </Pagination>
              </Card.Body>
              <Card.Body className="mobileDevice">
                {projects
                  .slice(activePage * 10, activePage * 10 + 10)
                  .map((item, index) => (
                    <TableContainer key={index}>
                      <TableHeader onClick={() =>
                        history.push(
                          `/project-detail/${item._id}`,
                          { referrer: props.route, partnership: props.partnership, rank: props.rank },
                        )
                      }>
                        <div style={{ width: '50%', textAlign: 'left' }}>#</div>
                        <div style={{ width: '50%', textAlign: 'right' }}>Project</div>
                      </TableHeader>
                      <TableBody
                        onClick={() =>
                          history.push(
                            `/project-detail/${item._id}`,
                            { referrer: props.route, partnership: props.partnership, rank: props.rank },
                          )
                        }>
                        <div style={{ width: '50%', textAlign: 'left' }}>{activePage * numberPerPage + index + 1}</div>
                        <div style={{ width: '50%', textAlign: 'right' }}>
                          <TitleContainer>
                            <ProjectImg
                              src={`${constants.api_url}/logos/${item.logoFile}`}

                            />
                            <span >{item.title}</span>
                          </TitleContainer>
                        </div>
                      </TableBody>
                      {
                        props.pending !== true && 
                        <>
                          <TableHeader onClick={() =>
                            history.push(
                              `/project-detail/${item._id}`,
                              { referrer: props.route, partnership: props.partnership, rank: props.rank },
                            )
                          }>
                            <div style={{ width: '50%', textAlign: 'left' }}>Tags</div>
                            <div style={{ width: '50%', textAlign: 'right' }}>TVL</div>
                          </TableHeader>
                          <TableBody
                            onClick={() =>
                              history.push(
                                `/project-detail/${item._id}`,
                                { referrer: props.route, partnership: props.partnership, rank: props.rank },
                              )
                            }>
                            <div style={{ width: '50%', textAlign: 'left' }}>{item.category}</div>
                            <div style={{ width: '50%', textAlign: 'right' }}>{item.tvl !== undefined && item.tvl !== 0 ? item.tvl.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}</div>
                          </TableBody>
                          <TableHeader onClick={() =>
                            history.push(
                              `/project-detail/${item._id}`,
                              { referrer: props.route, partnership: props.partnership, rank: props.rank },
                            )
                          }>
                            <div style={{ width: '50%', textAlign: 'left' }}>Token</div>
                            <div style={{ width: '50%', textAlign: 'right' }}>Price</div>
                          </TableHeader>
                          <TableBody
                            onClick={() =>
                              history.push(
                                `/project-detail/${item._id}`,
                                { referrer: props.route, partnership: props.partnership, rank: props.rank },
                              )
                            }>
                            <div style={{ width: '50%', textAlign: 'left' }}>{item.cmcData !== undefined && item.cmcData.tokenData !== undefined && item.cmcData.tokenData.symbol !== undefined && item.cmcData.tokenData.symbol.toUpperCase()}</div>
                            <div style={{ width: '50%', textAlign: 'right' }}>{(item.cmcData !== undefined && item.cmcData.tokenData !== undefined && item.cmcData.tokenData.market_data !== undefined && item.cmcData.tokenData.market_data.current_price !== undefined && item.cmcData.tokenData.market_data.current_price.usd !== undefined) ? item.cmcData.tokenData.market_data.current_price.usd.toFixed(4) : ''}</div>
                          </TableBody>
                          <TableHeader onClick={() =>
                            history.push(
                              `/project-detail/${item._id}`,
                              { referrer: props.route, partnership: props.partnership, rank: props.rank },
                            )
                          }>
                            <div style={{ width: '100%', textAlign: 'left' }}>24h Change</div>
                          </TableHeader>
                          <TableBody
                            onClick={() =>
                              history.push(
                                `/project-detail/${item._id}`,
                                { referrer: props.route, partnership: props.partnership, rank: props.rank },
                              )
                            }>
                            <div style={{ width: '100%', textAlign: 'left' }}>
                              {(item.cmcData !== undefined && item.cmcData.tokenData !== undefined && item.cmcData.tokenData.market_data !== undefined && item.cmcData.tokenData.market_data.price_change_24h !== undefined)
                                ? (item.cmcData.tokenData.market_data.price_change_24h && item.cmcData.tokenData.market_data.price_change_24h.toFixed(2))
                                : ''}%
                            </div>
                          </TableBody>
                          </>
                        }
                      </TableContainer>
                  ))}
                {
                  projects.length === 0 &&
                  <TableContainer>
                    <TableBody>
                      <NoProjectRow>No projects</NoProjectRow>
                    </TableBody>
                  </TableContainer>
                }
                <Pagination className="pageDiv">
                  <Pagination.Prev
                    onClick={() =>
                      activePage >= 1
                        ? setActivePage(activePage - 1)
                        : setActivePage(0)
                    }
                  />
                  {renderPagination()}
                  <Pagination.Next
                    onClick={() =>
                      activePage < lastPage
                        ? setActivePage(activePage + 1)
                        : setActivePage(lastPage)
                    }
                  />
                </Pagination>
              </Card.Body>
            </Card>
          </ProjectList>
        </ProjectBody>
      )}
    </ProjectContainer>
  )
}

export default Project
