import axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { Card, Spinner, Nav, Tab } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PageTitle } from '../../StyledComponents';
import BackIcon from '../../resource/left_arrow.svg';
import TwitterIcon from '../../resource/twitter.svg';
import DiscordIcon from '../../resource/discord.svg';
import GithubIcon from '../../resource/github.svg';
import TelegramIcon from '../../resource/telegram.svg';
import CoinMarketcapIcon from '../../resource/coin_marketcap.svg';
import Coingecko from '../../resource/coingecko.svg';
import MediumIcon from '../../resource/medium_icon.png';
import { constants } from '../../constants';
import styled from 'styled-components';
import React from 'react';

const ProjectDetailContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 10px;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
`;

const ProjectDetailBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 30px;
  margin: 0px 40px;
  background-color: #1d1d25;
  height: 100%;
  @media screen and (max-width: 768px) {
    padding: 24px 20px 24px;
  }
`;

const BackButton = styled.div`
  user-select: none;
  padding: 30px 40px;
  cursor: pointer;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  position: absolute;
  display: flex;
  align-items: center;

  color: #eff3fb;
  @media screen and (max-width: 768px) {
    position: unset;
  }
`;

const ProjectDetailList = styled.div`
  flex: 1;
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ProjectLinkRow = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
  }
`;

const ProjectInfoRow = styled.div`
  display: flex;
  flex: 1;
  margin-right: 60px;
  flex-direction: column;
  @media screen and (max-width: 768px) {
  }
`;

const ProjectSiteBtn = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;

  /* identical to box height */
  align-items: center;
  text-align: center;

  /* white */
  color: #ffffff;

  width: 100%;
  margin: 0;
  margin-bottom: 20px;
  cursor: pointer;
  padding: 12px;
  background: #1969ff;
  border-radius: 8px;
  user-select: none;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const ProjectLinkBtn = styled.div`
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  margin-bottom: 24px;
  text-align: left;
`;

const ProjectLinkImg = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border: none;
  border-radius: 50%;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const ProjectIntroImg = styled.img`
  width: 75px;
  height: 75px;
  margin-right: 20px;
  border: none;
`;

const ProjectIntroImgRound = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border: none;
  border-radius: 50%;
`;

const ProjectIntroDesc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const ProjectIntroTitle = styled.div`
  text-align: left;
  word-break: break-word;
  white-space: normal;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 39px;

  /* identical to box height */

  /* Semiwhite */
  color: #eff3fb;
`;

const ProjectIntroDetails = styled.div`
  margin: 0;
  text-align: left;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;

  /* identical to box height */

  color: #b7becb;
`;

const ProjectDetailTitle = styled.div`
  word-break: break-word;
  white-space: normal;
  font-family: Proxima Nova;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;

  /* identical to box height, or 29px */

  /* text-active */
  color: #eff3fb;
  margin-top: 60px;
  margin-bottom: 20px;
`;

const ProjectDetailDesc = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;

  /* or 28px */

  /* grey */
  color: #b7becb;
  text-align: left;
  margin-top: 12px;
`;

const BackItemImg = styled.img`
  margin-right: 12px;
  width: 12px;
`;

const ProjectSymbolContainer = styled.div`
  display: flex;
  margin: 20px 0;
  align-items: center;
`;

const ProjectIntroSymbol = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;

  /* identical to box height */

  /* Semiwhite */
  color: #eff3fb;
`;

const ProjectDetail = () => {
  let history = useHistory();
  const location = useLocation();
  const [project, setProject] = useState({});
  const [priceData, setPriceData] = useState([]);
  const [priceDate, setPriceDate] = useState([]);
  const [priceDataW, setPriceDataW] = useState([]);
  const [priceDateW, setPriceDateW] = useState([]);
  const [priceDataA, setPriceDataA] = useState([]);
  const [priceDateA, setPriceDateA] = useState([]);
  let { id } = useParams();

  const [isLoading, setLoading] = useState(true);

  if (id === undefined) {
    history.replace('/project/all');
  }

  const openLink = (url) => {
    window.open(url);
  };

  useEffect(() => {
    const updateProjectStatus = async () => {
      if (location.pathname.indexOf('project-detail') < 0) {
        return;
      }
      try {
        const res = await axios.get(
          `${constants.api_url}/get-project-detail?id=${id}`,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        var dataArray = [];
        var dateArray = [];
        if (
          res.data.priceData !== {} &&
          res.data.priceData !== undefined &&
          res.data.priceData.tokenData !== undefined &&
          res.data.priceData.tokenData.prices !== undefined &&
          res.data.priceData.tokenData.prices.length > 0
        ) {
          res.data.priceData.tokenData.prices.forEach((item) => {
            dataArray.push(item[1]);
            var d = new Date(item[0]);
            dateArray.push(d.toDateString());
          });
        }
        var dataArrayW = [];
        var dateArrayW = [];
        if (
          res.data.priceDataWeek !== {} &&
          res.data.priceDataWeek !== undefined &&
          res.data.priceDataWeek.tokenData !== undefined &&
          res.data.priceDataWeek.tokenData.prices !== undefined &&
          res.data.priceDataWeek.tokenData.prices.length > 0
        ) {
          res.data.priceDataWeek.tokenData.prices.forEach((item) => {
            dataArrayW.push(item[1]);
            var d = new Date(item[0]);
            dateArrayW.push(d.toDateString());
          });
        }

        var dataArrayA = [];
        var dateArrayA = [];
        if (
          res.data.priceDataAll !== {} &&
          res.data.priceDataAll !== undefined &&
          res.data.priceDataAll.tokenData !== undefined &&
          res.data.priceDataAll.tokenData.prices !== undefined &&
          res.data.priceDataAll.tokenData.prices.length > 0
        ) {
          res.data.priceDataAll.tokenData.prices.forEach((item) => {
            dataArrayA.push(item[1]);
            var d = new Date(item[0]);
            dateArrayA.push(d.toDateString());
          });
        }
        setProject(res.data || {});
        setPriceData(dataArray || []);
        setPriceDate(dateArray || []);
        setPriceDataW(dataArrayW || []);
        setPriceDateW(dateArrayW || []);
        setPriceDataA(dataArrayA || []);
        setPriceDateA(dateArrayA || []);

        setLoading(false);
      } catch (error) {
        //
        console.log(error);
      }

      setTimeout(updateProjectStatus, 1200000);
    };
    updateProjectStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var config = {
    credits: {
      enabled: false
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false
        },
        color: '#0D1FFF',
        lineWidth: 1
      }
    },
    xAxis: {
      title: {
        text: ''
      },
      categories: priceDate,
      lineWidth: 0,
      visible: true,
      labels: {
        enabled: false
      },
      accessibility: {
        enabled: false
      },
      max: priceDate[priceDate.lastIndex]
    },
    legend: {
      accessibility: {
        enabled: false
      }
    },
    series: [
      {
        showInLegend: false,
        data: priceData,
        name: 'Price:',
        type: 'spline'
      }
    ],
    title: {
      text: ''
    },
    chart: {
      backgroundColor: '#1D1D25',
      height: 240
    },
    yAxis: {
      title: {
        text: ''
      },
      gridLineWidth: 0,
      labels: {
        enabled: false
      }
    }
  };
  var config1 = {
    credits: {
      enabled: false
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false
        },
        color: '#0D1FFF',
        lineWidth: 1
      }
    },
    xAxis: {
      title: {
        text: ''
      },
      categories: priceDateW,
      lineWidth: 0,
      visible: true,
      labels: {
        enabled: false
      },
      accessibility: {
        enabled: false
      },
      max: priceDateW[priceDateW.lastIndex]
    },
    legend: {
      accessibility: {
        enabled: false
      }
    },
    series: [
      {
        showInLegend: false,
        data: priceDataW,
        name: 'Price:',
        type: 'spline'
      }
    ],
    title: {
      text: ''
    },
    chart: {
      backgroundColor: '#1D1D25',
      height: 240
    },
    yAxis: {
      title: {
        text: ''
      },
      gridLineWidth: 0,
      labels: {
        enabled: false
      }
    }
  };
  var config2 = {
    credits: {
      enabled: false
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false
        },
        color: '#0D1FFF',
        lineWidth: 1
      }
    },
    xAxis: {
      title: {
        text: ''
      },
      categories: priceDateA,
      lineWidth: 0,
      visible: true,
      labels: {
        enabled: false
      },
      accessibility: {
        enabled: false
      },
      max: priceDateA[priceDateA.lastIndex]
    },
    legend: {
      accessibility: {
        enabled: false
      }
    },
    series: [
      {
        showInLegend: false,
        data: priceDataA,
        name: 'Price:',
        type: 'spline'
      }
    ],
    title: {
      text: ''
    },
    chart: {
      backgroundColor: '#1D1D25',
      height: 240
    },
    yAxis: {
      title: {
        text: ''
      },
      gridLineWidth: 0,
      labels: {
        enabled: false
      }
    }
  };
  const handleBack = () => {
    if (location.state !== undefined) {
      let referrer = '/project/all';
      if (location.state.referrer !== undefined) {
        referrer =
          location.state.referrer === '/'
            ? location.state.referrer
            : '/project/' + location.state.referrer;
      } else if (
        location.state.rank !== undefined &&
        location.state.rank === true
      ) {
        referrer = '/rank-list';
      } else if (
        location.state.partnership !== undefined &&
        location.state.partnership
      ) {
        referrer = '/partnerships-integrations';
      }
      history.push(referrer);
    } else {
      history.push('/project/all');
    }
  };
  return (
    <ProjectDetailContainer>
      <BackButton onClick={() => handleBack()}>
        <BackItemImg src={BackIcon}></BackItemImg>
        Back
      </BackButton>
      <PageTitle>Fantom Project</PageTitle>
      {isLoading ? (
        <Spinner animation='border' variant='primary' />
      ) : (
        <ProjectDetailBody>
          <ProjectDetailList>
            <ProjectInfoRow>
              <FlexContainer style={{ paddingBottom: '30px' }}>
                <ProjectIntroImg
                  src={`${constants.api_url}/logos/${project.logoFile}`}
                />
                <ProjectIntroDesc>
                  <ProjectIntroTitle>{project.title}</ProjectIntroTitle>
                  <ProjectIntroDetails>
                    {project.shortIntro}
                  </ProjectIntroDetails>
                </ProjectIntroDesc>
              </FlexContainer>
              <ProjectSymbolContainer>
                <ProjectIntroImgRound
                  src={`${constants.api_url}/logos/${project.logoFile}`}
                />
                <ProjectIntroSymbol>
                  {project.cmcData !== {} &&
                  project.cmcData !== undefined &&
                  project.cmcData.tokenData !== undefined &&
                  project.cmcData.tokenData.symbol
                    ? project.cmcData.tokenData.symbol.toUpperCase()
                    : ''}
                </ProjectIntroSymbol>
              </ProjectSymbolContainer>
              {project.cryptoKek ? (
                <iframe
                  title='crypto-kek-chart'
                  loading='lazy'
                  src={project.cryptoKek}
                  width='100%'
                  height='600px'
                  frameBorder='0'
                  scrolling='no'
                  align='center'>
                  {' '}
                </iframe>
              ) : (
                <Card
                  style={{ backgroundColor: '#1D1D25', marginBottom: '30px' }}>
                  <Card.Header
                    style={{
                      backgroundColor: '#1D1D25',
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '0px'
                    }}>
                    {project.cmcData !== {} &&
                      project.cmcData !== undefined &&
                      project.cmcData.tokenData !== undefined &&
                      project.cmcData.tokenData.market_data !== undefined &&
                      project.cmcData.tokenData.market_data.current_price !==
                        undefined &&
                      project.cmcData.tokenData.market_data.current_price
                        .usd !== undefined && (
                        <Card.Title
                          style={{
                            color: '#EFF3FB',
                            fontSize: '30px',
                            fontWeight: 'bold',
                            marginRight: '6px'
                          }}>
                          $
                          {project.cmcData.tokenData.market_data.current_price.usd.toFixed(
                            4
                          )}
                        </Card.Title>
                      )}
                    {project.cmcData !== {} &&
                      project.cmcData !== undefined &&
                      project.cmcData.tokenData !== undefined &&
                      project.cmcData.tokenData.market_data !== undefined &&
                      project.cmcData.tokenData.market_data.price_change_24h !==
                        0 && (
                        <Card.Title
                          style={{
                            color: '#707B8F',
                            fontSize: '20px',
                            fontWeight: '700',
                            textAlign: 'left',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            lineHeight: '1.6'
                          }}>
                          {project.cmcData.tokenData.market_data
                            .price_change_24h &&
                            `${project.cmcData.tokenData.market_data.price_change_24h
                              .toFixed(2)
                              .toString()}%`}
                        </Card.Title>
                      )}
                  </Card.Header>
                  <Tab.Container
                    id='uncontrolled-tab-example'
                    defaultActiveKey='1W'>
                    <Tab.Content>
                      <Tab.Pane eventKey='1W'>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={config1}
                          style={{ marginTop: '30px' }}
                          containerProps={{ style: { width: '100%' } }}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey='1M'>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={config}
                          style={{ marginTop: '30px' }}
                          containerProps={{ style: { width: '100%' } }}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey='All'>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={config2}
                          style={{ marginTop: '30px' }}
                          containerProps={{ style: { width: '100%' } }}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                    <Nav variant='pills' className='flex-row details-nav'>
                      <Nav.Item>
                        <Nav.Link eventKey='1W'>1W</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey='1M'>1M</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey='All'>All</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Tab.Container>
                </Card>
              )}
              {project.tvl !== undefined && project.tvl !== 0 && (
                <Card className='item-card'>
                  <Card.Body style={{ background: '#17171E' }}>
                    <Card.Subtitle
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}>
                      Total Value Locked
                    </Card.Subtitle>
                    <Card.Text
                      style={{
                        textAlign: 'left',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        color: '#EFF3FB',
                        fontSize: '32px'
                      }}>
                      $
                      {project.tvl
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </Card.Text>
                  </Card.Body>
                </Card>
              )}
              {project.detailIntro && (
                <React.Fragment>
                  <ProjectDetailTitle>{`About ${project.title}`}</ProjectDetailTitle>
                  <ProjectDetailDesc>{project.detailIntro}</ProjectDetailDesc>
                </React.Fragment>
              )}
            </ProjectInfoRow>
            <ProjectLinkRow>
              <ProjectSiteBtn onClick={() => openLink(project.website)}>
                Visit Website
              </ProjectSiteBtn>
              {project.twitter !== '' && (
                <ProjectLinkBtn onClick={() => openLink(`${project.twitter}`)}>
                  <ProjectLinkImg src={TwitterIcon}></ProjectLinkImg>
                  Twitter
                </ProjectLinkBtn>
              )}
              {project.discord !== '' && (
                <ProjectLinkBtn onClick={() => openLink(`${project.discord}`)}>
                  <ProjectLinkImg src={DiscordIcon}></ProjectLinkImg>
                  Discord
                </ProjectLinkBtn>
              )}
              {project.telegram !== '' && (
                <ProjectLinkBtn onClick={() => openLink(`${project.telegram}`)}>
                  <ProjectLinkImg src={TelegramIcon}></ProjectLinkImg>
                  Telegram
                </ProjectLinkBtn>
              )}
              {project.github !== '' && (
                <ProjectLinkBtn onClick={() => openLink(`${project.github}`)}>
                  <ProjectLinkImg src={GithubIcon}></ProjectLinkImg>
                  Github
                </ProjectLinkBtn>
              )}
              {project.coinmarketcap !== '' && (
                <ProjectLinkBtn
                  onClick={() => openLink(`${project.coinmarketcap}`)}>
                  <ProjectLinkImg src={CoinMarketcapIcon}></ProjectLinkImg>
                  Coinmarketcap
                </ProjectLinkBtn>
              )}
              {project.coingecko !== undefined && (
                <ProjectLinkBtn
                  onClick={() => openLink(`${project.coingecko}`)}>
                  <ProjectLinkImg src={Coingecko}></ProjectLinkImg>
                  CoinGecko
                </ProjectLinkBtn>
              )}
              {project.medium !== '' && (
                <ProjectLinkBtn onClick={() => openLink(`${project.medium}`)}>
                  <ProjectLinkImg src={MediumIcon}></ProjectLinkImg>
                  Medium
                </ProjectLinkBtn>
              )}
            </ProjectLinkRow>
          </ProjectDetailList>
        </ProjectDetailBody>
      )}
    </ProjectDetailContainer>
  );
};

export default ProjectDetail;
