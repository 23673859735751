import axios from 'axios'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useEffect, useState } from 'react'
import { Card, Spinner, Tabs, Tab, Pagination } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Web3 from 'web3';
import { bignumber } from 'mathjs';
import { gql, useQuery } from "@apollo/client";
import { PageTitle, TableHeader, TableBody } from '../../StyledComponents'
import { constants } from '../../constants'

import './Home.css';

const HomeContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #121318;
`

const HomeBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 40px 20px;
  @media screen and (max-width: 768px) {
    padding: 20px 20px 20px;
  }
`

const HomeList = styled.div`
  flex: 1;
  border-width: 1px 0 0;
`

const TitleContainer = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 768px) {
    text-align: right;
    justify-content: flex-end;
  }
`

const ProjectImg = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 50%;
`

const RowNoColumn = styled.div`
  width: 5%;
  text-align: center;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;

  /* identical to box height */

  color: #B7BECB;
`;

const TableContainer = styled.div`
  padding: 20px;
  margin-bottom: 10px;
  width: 100%;
  background: #14151A;
  border-radius: 8px;
`

const Home = () => {
  const numberPerPage = 10;
  let history = useHistory()
  const location = useLocation()
  const [projects, setProjects] = useState([]);
  const [tvlData, setTVL] = useState([]);
  const [tvlDate, setTVLDate] = useState([]);
  const [blockTime, setBlockTime] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(0)
  const [totalTVL, setTotalTVL] = useState(0);

  const renderPagination = () => {
    const numPages = Math.ceil(projects.length / numberPerPage);
    const pageLimit = 4;
    if (numPages <= pageLimit) {
      return (
        <>
          {[...Array(numPages).keys()].map((item, idx) => (
            <Pagination.Item
              key={idx}
              active={item === activePage}
              onClick={() => setActivePage(item)}
            >
              {item + 1}
            </Pagination.Item>
          ))}
        </>
      )
    } else {
      let totalArr = [...Array(numPages).keys()]
      let startIndex = activePage <= 2 ? 0 : activePage - 2
      if (startIndex + pageLimit > numPages) {
        startIndex = numPages - pageLimit
      }
      const targetArr = totalArr.slice(startIndex, startIndex + 4)
      return (
        <>
          {activePage >= 3 && <Pagination.Ellipsis></Pagination.Ellipsis>}
          {targetArr.map((item, idx) => (
            <Pagination.Item
              key={idx}
              active={item === activePage}
              onClick={() => setActivePage(item)}
            >
              {item + 1}
            </Pagination.Item>
          ))}
          {activePage < numPages - 3 && (
            <Pagination.Ellipsis></Pagination.Ellipsis>
          )}
        </>
      )
    }
  }

  var web3 = new Web3(constants.rpc_api);
  const dataQuery = gql`
    query State {
      state {
          blocks
          transactions
      }
  }`
  const { data } = useQuery(dataQuery, { pollInterval: 5000 });

  // const txTransaction = parseFloat(bignumber(data.state.transactions).toString().replace(/\B(?=(\d{3})+(?!\d))/g)/1000000  - parseFloat(bignumber(latest_1.timestampNano).toString())/1000000;
  const compareTVL = (a, b) => {
    const aVal = a.tvl !== undefined ? a.tvl : 0;
    const bVal = b.tvl !== undefined ? b.tvl : 0;

    if (aVal > bVal) {
      return -1;
    }
    if (aVal < bVal) {
      return 1;
    }

    return 0;
  }

  useEffect(() => {
    const updateProjectStatus = async () => {
      if (location.pathname.indexOf('-') >= 0) {
        return
      }
      try {
        const res = await axios.get(
          `${constants.api_url}/get-project`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            params: {
              category: 'All',
            }
          },
        )

        var dataArray = [];
        var dateArray = [];
        axios.get(
          `${constants.llma_api}/charts/Fantom`
        )
        .then(response => {
          if ('data' in response && response.data.length > 0) {
            setTotalTVL(response.data[response.data.length - 1].totalLiquidityUSD);

            response.data.forEach((itemH, index) => {
              dataArray.push(parseFloat(itemH.totalLiquidityUSD));

              var timeDate = new Date(itemH.date * 1000);
              const monthIndex = timeDate.getMonth()
              const monthName = months[monthIndex]
              dateArray.push(monthName + ' ' + timeDate.getDate());
            });
          }
          setTVL(dataArray || []);
          setTVLDate(dateArray || []);
        })

        const months = {
          0: 'Jan',
          1: 'Feb',
          2: 'Mar',
          3: 'Apr',
          4: 'May',
          5: 'Jun',
          6: 'Jul',
          7: 'Aug',
          8: 'Sep',
          9: 'Oct',
          10: 'Nov',
          11: 'Dec'
        }

        res.data.sort(compareTVL);
        setProjects(res.data || [])
        setLoading(false);
      } catch (error) {
        //
        console.log("error:", error)
      }
      setTimeout(updateProjectStatus, 1200000);
    }

    const calcBlockTime = async () => {
      const tx = await web3.eth.getBlockNumber();
      const latest = await web3.eth.getBlock(tx);
      const latest_1 = await web3.eth.getBlock(tx - 1);
      if (latest !== null && latest_1 !== null && latest.timestampNano != null && latest_1.timestampNano != null) {
        const bTime = parseFloat(bignumber(latest.timestampNano).toString()) / 1000000 - parseFloat(bignumber(latest_1.timestampNano).toString()) / 1000000;
        setBlockTime(bTime.toFixed(0));
      }
      setTimeout(calcBlockTime, 5000);
    }

    updateProjectStatus();
    calcBlockTime();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tvlLength = tvlData.length;
  var config = {
    credits: {
      enabled: false
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false
        },
        color: '#0D1FFF',
        lineWidth: 1
      }
    },
    xAxis: {
      title: {
        text: '',
      },
      categories: tvlDate,
      lineWidth: 0,
      visible: true,
      labels: {
        enabled: true,
        style: {
          color: 'rgba(235, 235, 245, 0.3)',
          fontSize: '14px'
        }
      },
      accessibility: {
        enabled: false,
      },
    },
    legend: {
      accessibility: {
        enabled: false,
      }
    },
    series: [
      {
        showInLegend: false,
        data: tvlData,
        name: 'TVL:',
        type: 'spline'
      },
    ],
    title: {
      text: '',
    },
    chart: {
      backgroundColor: '#1D1D25',
      height: 240,

    },
    yAxis: {
      title: {
        text: '',
      },
      gridLineWidth: 0,
      labels: {
        formatter: function () {
          if (this.value > 1000000) return "$" + Highcharts.numberFormat(this.value / 1000000, 0) + "m";
          if (this.value > 1000) return "$" + Highcharts.numberFormat(this.value / 1000, 0) + "k";
          return "$" + Highcharts.numberFormat(this.value, 0);
        },
        style: {
          color: 'rgba(235, 235, 245, 0.3)',
          fontSize: '14px'
        }
      },
    },
  }
  var config1 = {
    credits: {
      enabled: false
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false
        },
        color: '#0D1FFF',
        lineWidth: 1
      }
    },
    xAxis: {
      title: {
        text: '',
      },
      categories: tvlDate.slice(tvlLength - 7),
      lineWidth: 0,
      visible: true,
      labels: {
        enabled: true,
        style: {
          color: 'rgba(235, 235, 245, 0.3)',
          fontSize: '14px'
        }
      },
      accessibility: {
        enabled: false,
      },
    },
    legend: {
      accessibility: {
        enabled: false,
      }
    },
    series: [
      {
        showInLegend: false,
        data: tvlData.slice(tvlLength - 7),
        name: 'Price:',
        type: 'spline'
      },
    ],
    title: {
      text: '',
    },
    chart: {
      backgroundColor: '#1D1D25',
      height: 240,

    },
    yAxis: {
      title: {
        text: '',
      },
      gridLineWidth: 0,
      labels: {
        formatter: function () {
          if (this.value > 1000000) return "$" + Highcharts.numberFormat(this.value / 1000000, 0) + "m";
          if (this.value > 1000) return "$" + Highcharts.numberFormat(this.value / 1000, 0) + "k";
          return "$" + Highcharts.numberFormat(this.value, 0);
        },
        style: {
          color: 'rgba(235, 235, 245, 0.3)',
          fontSize: '14px'
        }
      },
    },
  }
  var config2 = {
    credits: {
      enabled: false
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false
        },
        color: '#0D1FFF',
        lineWidth: 1
      }
    },
    xAxis: {
      title: {
        text: '',
      },
      categories: tvlDate.slice(tvlLength - 30),
      lineWidth: 0,
      visible: true,
      labels: {
        enabled: true,
        style: {
          color: 'rgba(235, 235, 245, 0.3)',
          fontSize: '14px'
        }
      },
      accessibility: {
        enabled: false,
      },
    },
    legend: {
      accessibility: {
        enabled: false,
      }
    },
    series: [
      {
        showInLegend: false,
        data: tvlData.slice(tvlLength - 30),
        name: 'Price:',
        type: 'spline'
      },
    ],
    title: {
      text: '',
    },
    chart: {
      backgroundColor: '#1D1D25',
      height: 240,

    },
    yAxis: {
      title: {
        text: '',
      },
      gridLineWidth: 0,
      labels: {
        formatter: function () {
          if (this.value > 1000000) return "$" + Highcharts.numberFormat(this.value / 1000000, 0) + "m";
          if (this.value > 1000) return "$" + Highcharts.numberFormat(this.value / 1000, 0) + "k";
          return "$" + Highcharts.numberFormat(this.value, 0);
        },
        style: {
          color: 'rgba(235, 235, 245, 0.3)',
          fontSize: '14px'
        }
      },
    },
  }

  const lastPage = Math.ceil(projects.length / numberPerPage) - 1;

  return (
    <HomeContainer>
      {isLoading ? (<Spinner animation="border" variant="primary" />) : (
        <HomeBody>
          <PageTitle>Fantom Project</PageTitle>
          <HomeList>
            <Card className="main-card">
              <Card className="sub-card">
                <Card.Header>
                  <Card.Title
                    style={{
                    }}
                  >
                    Total Value Locked in Fantom DeFi
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Tabs variant="pills" className="period-selector" defaultActiveKey="All" id="uncontrolled-tab-example">
                    <Tab eventKey="1W" title="1W">
                      <HighchartsReact highcharts={Highcharts} options={config1} containerProps={{ style: { width: '100%' } }} />
                    </Tab>
                    <Tab eventKey="1M" title="1M">
                      <HighchartsReact highcharts={Highcharts} options={config2} containerProps={{ style: { width: '100%' } }} />
                    </Tab>
                    <Tab eventKey="All" title="All">
                      <HighchartsReact highcharts={Highcharts} options={config} containerProps={{ style: { width: '100%' } }} />
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
              <Card className="info-card">
                <Card className="item-card">
                  <Card.Body>
                    <Card.Subtitle style={{
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                      Total Value Locked
                    </Card.Subtitle>
                    <Card.Text
                      style={{
                        textAlign: 'left',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        color: '#EFF3FB',
                        fontSize: '32px'
                      }}
                    >
                      ${totalTVL !== 0 ? totalTVL.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card className="item-card">
                  <Card.Body>
                    <Card.Subtitle
                      style={{
                        textAlign: 'left',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      Block time
                    </Card.Subtitle>
                    <Card.Text
                      style={{
                        textAlign: 'left',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        color: '#EFF3FB',
                        fontSize: '32px'
                      }}
                    >
                      {blockTime}ms
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card className="item-card">
                  <Card.Body>
                    <Card.Subtitle
                      style={{
                        textAlign: 'left',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      Total Txs
                    </Card.Subtitle>
                    <Card.Text
                      style={{
                        textAlign: 'left',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        color: '#EFF3FB',
                        fontSize: '32px'
                      }}
                    >
                      {data !== undefined ? parseFloat(bignumber(data.state.transactions).toString()).toLocaleString() : 0}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Card>
            </Card>
            <Card style={{ backgroundColor: '#1D1D25', marginTop: '30px' }}>
              <Card.Header>
                <Card.Title
                  style={{
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  Rank&nbsp;{projects.length > 0 ? ('(' + projects.length + ')') : ''}
                </Card.Title>
              </Card.Header>
              <Card.Body className="desktopDevice">
                <TableHeader>
                  <div style={{ width: '5%', textAlign: 'center' }}>#</div>
                  <div style={{ width: '20%', textAlign: 'left' }}>Project</div>
                  <div style={{ width: '10%', textAlign: 'right' }}>Tags</div>
                  <div style={{ width: '20%', textAlign: 'center' }}>TVL</div>
                  <div style={{ width: '10%', textAlign: 'center' }}>Token</div>
                  <div style={{ width: '15%', textAlign: 'center' }}>Price</div>
                  <div style={{ width: '20%', textAlign: 'center' }}>24hr change</div>
                </TableHeader>
                {projects
                  .slice(activePage * numberPerPage, (activePage + 1) * numberPerPage)
                  .map((item, index) => (
                    <TableBody
                      key={index}
                      onClick={() =>
                        history.push(
                          `/project-detail/${item._id}`,
                          { referrer: '/' },
                        )
                      }>
                      <RowNoColumn>{activePage * numberPerPage + index + 1}</RowNoColumn>
                      <div style={{ width: '20%', textAlign: 'center' }}>
                        <TitleContainer>
                          <ProjectImg
                            src={`${constants.api_url}/logos/${item.logoFile}`}
                          />
                          <span>{item.title}</span>
                        </TitleContainer>
                      </div>
                      <div style={{ width: '10%', textAlign: 'right' }}>{item.subcategory !== undefined && item.subcategory !== '' ? item.subcategory : item.category}</div>
                      <div style={{ width: '20%', textAlign: 'center' }}>{item.tvl !== undefined && item.tvl !== 0 ? item.tvl.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}</div>
                      <div style={{ width: '10%', textAlign: 'center' }}>{item.cmcData !== undefined && item.cmcData.tokenData !== undefined && item.cmcData.tokenData.symbol !== undefined && item.cmcData.tokenData.symbol.toUpperCase()}</div>
                      <div style={{ width: '15%', textAlign: 'center' }}>{(item.cmcData !== undefined && item.cmcData.tokenData !== undefined && item.cmcData.tokenData.market_data !== undefined && item.cmcData.tokenData.market_data.current_price !== undefined && item.cmcData.tokenData.market_data.current_price.usd !== undefined) ? item.cmcData.tokenData.market_data.current_price.usd.toFixed(4) : ''}</div>
                      <div style={{ width: '20%', textAlign: 'center' }}>
                        {(item.cmcData !== undefined && item.cmcData.tokenData !== undefined && item.cmcData.tokenData.market_data !== undefined && item.cmcData.tokenData.market_data.price_change_24h)
                          ? (item.cmcData.tokenData.market_data.price_change_24h.toFixed(2) + '%')
                          : ''}
                      </div>
                    </TableBody>
                  ))}
                <Pagination className="pageDiv">
                  <Pagination.First onClick={() => setActivePage(0)} />
                  <Pagination.Prev
                    onClick={() =>
                      activePage >= 1
                        ? setActivePage(activePage - 1)
                        : setActivePage(0)
                    }
                  />
                  {renderPagination()}
                  <Pagination.Next
                    onClick={() =>
                      activePage < lastPage
                        ? setActivePage(activePage + 1)
                        : setActivePage(lastPage)
                    }
                  />
                  <Pagination.Last onClick={() => setActivePage(lastPage)} />
                </Pagination>
              </Card.Body>
              <Card.Body className="mobileDevice">
                {projects
                  .slice(activePage * numberPerPage, (activePage + 1) * numberPerPage)
                  .map((item, index) => (
                    <TableContainer key={index}>
                      <TableHeader onClick={() =>
                        history.push(
                          `/project-detail/${item._id}`,
                          { referrer: '/' },
                        )
                      }>
                        <div style={{ width: '50%', textAlign: 'left' }}>#</div>
                        <div style={{ width: '50%', textAlign: 'right' }}>Project</div>
                      </TableHeader>
                      <TableBody
                        onClick={() =>
                          history.push(
                            `/project-detail/${item._id}`,
                            { referrer: '/' },
                          )
                        }>
                        <div style={{ width: '50%', textAlign: 'left' }}>{activePage * numberPerPage + index + 1}</div>
                        <div style={{ width: '50%', textAlign: 'right' }}>
                          <TitleContainer>
                            <ProjectImg
                              src={`${constants.api_url}/logos/${item.logoFile}`}
                            />
                            <span >{item.title}</span>
                          </TitleContainer>
                        </div>
                      </TableBody>
                      <TableHeader onClick={() =>
                        history.push(
                          `/project-detail/${item._id}`,
                          { referrer: '/' },
                        )
                      }>
                        <div style={{ width: '50%', textAlign: 'left' }}>Tags</div>
                        <div style={{ width: '50%', textAlign: 'right' }}>TVL</div>
                      </TableHeader>
                      <TableBody
                        onClick={() =>
                          history.push(
                            `/project-detail/${item._id}`,
                            { referrer: '/' },
                          )
                        }>
                        <div style={{ width: '50%', textAlign: 'left' }}>{item.category}</div>
                        <div style={{ width: '50%', textAlign: 'right' }}>{item.tvl !== undefined && item.tvl !== 0 ? item.tvl.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}</div>
                      </TableBody>
                      <TableHeader onClick={() =>
                        history.push(
                          `/project-detail/${item._id}`,
                          { referrer: '/' },
                        )
                      }>
                        <div style={{ width: '50%', textAlign: 'left' }}>Token</div>
                        <div style={{ width: '50%', textAlign: 'right' }}>Price</div>
                      </TableHeader>
                      <TableBody
                        onClick={() =>
                          history.push(
                            `/project-detail/${item._id}`,
                            { referrer: '/' },
                          )
                        }>
                        <div style={{ width: '50%', textAlign: 'left' }}>{item.cmcData !== undefined && item.cmcData.tokenData !== undefined && item.cmcData.tokenData.symbol !== undefined && item.cmcData.tokenData.symbol.toUpperCase()}</div>
                        <div style={{ width: '50%', textAlign: 'right' }}>{(item.cmcData !== undefined && item.cmcData.tokenData !== undefined && item.cmcData.tokenData.market_data !== undefined && item.cmcData.tokenData.market_data.current_price !== undefined && item.cmcData.tokenData.market_data.current_price.usd !== undefined) ? item.cmcData.tokenData.market_data.current_price.usd.toFixed(4) : ''}</div>
                      </TableBody>
                      <TableHeader onClick={() =>
                        history.push(
                          `/project-detail/${item._id}`,
                          { referrer: '/' },
                        )
                      }>
                        <div style={{ width: '100%', textAlign: 'left' }}>24h Change</div>
                      </TableHeader>
                      <TableBody
                        onClick={() =>
                          history.push(
                            `/project-detail/${item._id}`,
                            { referrer: '/' },
                          )
                        }>
                        <div style={{ width: '100%', textAlign: 'left' }}>
                          {(item.cmcData !== undefined && item.cmcData.tokenData !== undefined && item.cmcData.tokenData.market_data !== undefined && item.cmcData.tokenData.market_data.price_change_24h)
                            ? (item.cmcData.tokenData.market_data.price_change_24h && item.cmcData.tokenData.market_data.price_change_24h.toFixed(2))
                            : ''}%
                        </div>
                      </TableBody>
                    </TableContainer>
                  ))}
                <Pagination className="pageDiv">
                  <Pagination.Prev
                    onClick={() =>
                      activePage >= 1
                        ? setActivePage(activePage - 1)
                        : setActivePage(0)
                    }
                  />
                  {renderPagination()}
                  <Pagination.Next
                    onClick={() =>
                      activePage < lastPage
                        ? setActivePage(activePage + 1)
                        : setActivePage(lastPage)
                    }
                  />
                </Pagination>
              </Card.Body>
            </Card>
          </HomeList>
        </HomeBody>
      )}

    </HomeContainer>
  )
}

export default Home
