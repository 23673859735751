import React from 'react'
import { useState, useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { Collapse } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'
import { ethers } from 'ethers'
import axios from 'axios'
import styled, { css } from 'styled-components'

import CreationForm from './Container/CreationForm/CreationForm'
import Home from './Container/Home/Home'
import ProjectDetail from './Container/ProjectDetail/ProjectDetail'
import Project from './Container/Project'
import RpcServer from './Container/RpcServer/RpcServer'
import CategoryCreation from './Container/CategoryCreation/CategoryCreation'
import { constants } from './constants'

import LogoIcon from './resource/logo.svg'
import HomeIcon from './resource/home.svg'
import RankIcon from './resource/rank.svg'
import ServerIcon from './resource/rpc_server.svg'
import FTMNode from './resource/ftm_node.png'
import ProjectIcon from './resource/project.svg'
import HomeActiveIcon from './resource/home_active.svg'
import RankActiveIcon from './resource/rank_active.svg'
import ServerActiveIcon from './resource/rpc_server_active.svg'
import ProjectActiveIcon from './resource/project_active.svg'
import DownArrowIcon from './resource/down_arrow.svg'
import UpArrowIcon from './resource/up_arrow.svg'
import HamburgerIcon from './resource/hamburger.svg'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css'
import './App.css'

const PageContainer = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'hidden',
  height: 'calc(100vh)'
})

const MenuContainer = styled.div`
  width: 240px;
  flex: none;
  display: flex;
  flex-direction: column;
  background: #17171E;
  position: unset;
  userSelect: none;
  padding: 40px 30px;
  @media screen and (max-width: 768px) {
    position: fixed;
    z-index: 9999;
    bottom: 0px;
    top: 0px;
  }
`

const BodyContainer = styled.div({
  flex: 1,
  overflowY: 'scroll',
  background: '#121318',
})

const MenuLogoContainer = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    display: none;
  }

`
const MenuHeaderContainer = styled.div`
  padding: 17px 24px;
  display: flex;
  justify-content: center;
  width: 100%;
  background: #121318;
  flex-direction: row;
  @media screen and (min-width: 769px) {
    display: none;
    position: relative;
  }
`

const MenuLogoImg = styled.img`
  vertical-align: middle;
  cursor: pointer;
  width: 100%;
  @media screen and (max-width: 768px) {
    height: 42px;
  }
`

const MenuScrollContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  height: calc(100% - 134px);
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: transparent;
  }
  @media screen and (max-width: 768px) {
    height: calc(100% - 0px);
    z-index: 9999;
  }

  &:hover::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: hsla(0,0%,100%,0.6);
  }
`

const MenuTopSection = styled.ul`
  list-style: none;
  padding-inline-start: 0;
`

const MenuTopItem = styled.li`
  padding: 0;
  margin-bottom: 40px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #B7BECB;
  cursor: pointer;
  list-style: none;
  align-items: center;
  display: flex;
  opacity: .6;

  span {
    line-height: 1;
  }

  ${props => props.active && css`
    opacity: 1;
    span {
      color: #05D9FF;
    }
  `}
`

const MenuBottomItem = styled.li`
  padding: 0;
  margin-top: 21px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #B7BECB;
  cursor: pointer;
  list-style: none;
  align-items: center;
  justify-content: space-between;
  display: flex;
  opacity: .6;

  span {
    line-height: 1;
  }

  ${props => props.active && css`
    opacity: 1;
    span {
      color: #05D9FF;
    }
  `}
`

const SubcategoryItem = styled.li`
  padding: 0;
  padding-left: 20px;
  margin-top: 21px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #B7BECB;
  cursor: pointer;
  list-style: none;
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  opacity: .6;

  span {
    line-height: 1;
  }

  ${props => props.active && css`
    opacity: 1;
    span {
      color: #05D9FF;
    }
  `}
`

const MenuItemImg = styled.img`
  margin-right: 16px;
  width: 30px;
`

const DownArrow = styled.img`
  float: right;
  width: 12px;
  cursor: pointer;
`

const MenuBottomHeader = styled.div`
  margin-right: 5px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

const MenuBottomTitle = styled.p`
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #B7BECB;
  flex: 1;


  ${props => props.active && css`
    opacity: 1;
    color: #05D9FF;
  `}
`

const MenuBottomButton = styled.a`
  background: #202F49;
  border-radius: 8px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  text-align: center;

  /* white */
  color: #FFFFFF;
  text-decoration: none;
  width: 100%;
  padding: 15px 0;

  &:hover {
    color: #FFFFFF;
    text-decoration: none;
  }
`

const MenuBlockchainContainer = styled.ul`
  list-style: none;
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 46px;
  margin-right: 5px;
`

const HamburgerImg = styled.img`
  display: block;
  width: 32px;
  height: 32px;
  left: 5px;
`

const ConnectButton = styled.button`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  text-align: center;

  /* white */
  color: #FFFFFF;
  background: #202F49;
  border-radius: 8px;
  width: 158px;
  height: 48px;
  border: none;
  position: fixed;
  left: 50%;
  top: 150px;
  transform: translateX(-50%);
`

const App = () => {
  let history = useHistory()
  const location = useLocation()
  const [isHide, setHide] = useState(true);
  const [categories, setCategories] = useState([]);
  const [isCategoryExpanded, setCategoryExpanded] = useState([]);

  const adminAddresses = ['0xD6D8bD42e87f44fAd9e14F8828d3853409DF6766','0x1bfFB3a232E06E06A5D9e93C8DF3321f768197c2', '0xB7bC6D2666e73F8Cd143a929DB5404e2fc03eA89', '0x997C7BD5F1938c7a511992d060B43B014fd30c25'];
  const reviewerAddresses = ['0xD6D8bD42e87f44fAd9e14F8828d3853409DF6766','0x1bfFB3a232E06E06A5D9e93C8DF3321f768197c2', '0xB7bC6D2666e73F8Cd143a929DB5404e2fc03eA89', '0x997C7BD5F1938c7a511992d060B43B014fd30c25'];
  const [accounts, setAccounts] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isReviewer, setIsReviewer] = useState(false);

  const connectMetamask = async () => {
    if (window.ethereum === undefined) {
      return;
    }
    await window.ethereum.enable();
    let provider = new ethers.providers.Web3Provider(window.ethereum)
    const accountList = await provider.listAccounts()
    setAccounts(accountList)
    setIsAdmin(false);
    setIsReviewer(false);
    for (var i in accountList) {
      if (adminAddresses.includes(accountList[i])) {
        setIsAdmin(true);
        break;
      }
    }
    for (i in accountList) {
      if (reviewerAddresses.includes(accountList[i])) {
        setIsReviewer(true);
        break;
      }
    }
  }

  useEffect(() => {
    if (location.pathname.indexOf('admin-app-secret') >= 0 || location.pathname.indexOf('admin-app-review') >= 0 || location.pathname.indexOf('admin-fjcnandfyz') >= 0) {
      connectMetamask();
    }

    const getCategories = async () => {
      const res = await axios.get(
        `${constants.api_url}/get-categories`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      setCategories(res.data);
      const categoryExpanded = Array(res.data.length + 1).fill(false);
      setCategoryExpanded(categoryExpanded)
    }

    getCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleCategory = (idx = 0) => {
    var collapsed = isCategoryExpanded;
    collapsed[idx] = !collapsed[idx];
    setCategoryExpanded([...collapsed]);
  }

  const handleCategoryClick = (idx, route) => {
    toggleCategory(idx)
    history.push(route)
  }

  return (
    <div onClick={(e)=>{
      var closest = e.target.closest(".left");
      if(isHide === false && closest === null) {
        setHide(true);
      }
    }}>
      <MenuHeaderContainer>
        <button className="navbar-toggler custom-button" onClick={()=>{
            var hide = isHide;
            setHide(!hide);
        }} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <HamburgerImg src={HamburgerIcon}></HamburgerImg>
        </button>
        <MenuLogoImg src={LogoIcon} alt='menu-logo' onClick={() => history.push("/")}/>
      </MenuHeaderContainer>
      <PageContainer>
        <MenuContainer className={isHide?'left':'right'}>
          <MenuLogoContainer>
            <MenuLogoImg src={LogoIcon} alt='menu-logo' onClick={() => history.push("/")}/>
          </MenuLogoContainer>
          <MenuScrollContainer>
            <MenuTopSection>
              <MenuTopItem
                active={location.pathname === '/' ? true : false}
                onClick={() => history.push("/")}>
                <MenuItemImg src={location.pathname === '/' ? HomeActiveIcon : HomeIcon}></MenuItemImg>
                <span>Home</span>
              </MenuTopItem>
              <MenuTopItem
                active={location.pathname.indexOf('rank-list') >= 0 ? true : false}
                onClick={() => history.push("/rank-list")}>
                <MenuItemImg src={location.pathname.indexOf('rank-list') >= 0 ? RankActiveIcon : RankIcon}></MenuItemImg>
                <span>Rank</span>
              </MenuTopItem>
              <MenuTopItem
                active={location.pathname.indexOf('rpcserver') >= 0 ? true : false}
                onClick={() => history.push("/rpcserver")}>
                <MenuItemImg src={location.pathname.indexOf('rpcserver') >= 0 ? ServerActiveIcon : ServerIcon}></MenuItemImg>
                <span>RPC Servers</span>
              </MenuTopItem>
              <MenuTopItem
                active={location.pathname.indexOf('partnerships-integrations') >= 0 ? true : false}
                onClick={() => history.push("/partnerships-integrations")}>
                <MenuItemImg src={FTMNode}></MenuItemImg>
                <span>Partnerships & Integration</span>
              </MenuTopItem>
            </MenuTopSection>
            <MenuBottomHeader onClick={() => toggleCategory()}>
              <MenuItemImg src={location.pathname.indexOf('/submit') >= 0 ? ProjectActiveIcon : ProjectIcon}></MenuItemImg>
              <MenuBottomTitle active={location.pathname.indexOf('/submit') >= 0 ? true : false}>
                Project
              </MenuBottomTitle>
              <DownArrow src={isCategoryExpanded[0] ? UpArrowIcon : DownArrowIcon}></DownArrow>
            </MenuBottomHeader>
            <MenuBlockchainContainer>
              <Collapse in={isCategoryExpanded[0]}>
              <div>
              {
                categories.map((category, idx) => {
                  return <React.Fragment key={idx + 1}>
                      <MenuBottomItem
                      active={location.pathname === (`/project/${category.route}`) ? true : false}
                      onClick={() => handleCategoryClick(idx + 1, `/project/${category.route}`)}>
                      <span>{category.title}</span>
                      {
                        'subcats' in category && category.subcats.length > 0 &&
                          <DownArrow src={isCategoryExpanded[idx + 1] ? UpArrowIcon : DownArrowIcon}></DownArrow>
                      }
                    </MenuBottomItem>
                    {
                      'subcats' in category && category.subcats.length > 0 &&
                      <Collapse in={isCategoryExpanded[idx + 1]}>
                      <div>
                      {
                        category.subcats.map((subcat, subcatidx) => {
                          return <SubcategoryItem
                            key={subcatidx}
                            active={location.pathname === `/project/${subcat.route}` ? true : false}
                            onClick={() => history.push(`/project/${subcat.route}`)}
                            >
                              <span>{subcat.title}</span>
                            </SubcategoryItem>
                        })
                      }
                      </div>
                      </Collapse>
                    }
                  </React.Fragment>
                })
              }
              </div>
              </Collapse>
            </MenuBlockchainContainer>
          </MenuScrollContainer>
          <MenuBottomButton href="/submit">Submit project</MenuBottomButton>
        </MenuContainer>
        <BodyContainer>
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
            <Route exact path='/rank-list'>
              <Project key='/rank-list' rank={true} title="Rank" category="All"/>
            </Route>
            {
              categories.map((category, idx) => {
                return <Route key={idx} exact path={`/project/${category.route}`}>
                    <Project route={category.route} category={category.title}/>
                  </Route>
              })
            }
            {
              categories.map((category) => {
                if ('subcats' in category && category.subcats.length > 0) {
                  return category.subcats.map((subcat, idx) => {
                    return <Route key={'sub' + idx} exact path={`/project/${subcat.route}`}>
                        <Project route={subcat.route} category={category.title} subcategory={subcat.title}/>
                      </Route>
                  })
                } else {
                  return null;
                }
              })
            }
            <Route exact path='/project-detail/:id'>
              <ProjectDetail />
            </Route>
            <Route exact path='/partnerships-integrations'>
              <Project key='/partnerships-integrations' partnership={1} title="Partnerships & Integration"/>
            </Route>
            <Route exact path='/review/:id'>
              <CreationForm categories={categories}/>
            </Route>
            <Route exact path='/submit'>
              <CreationForm categories={categories}/>
            </Route>
            <Route exact path='/rpcserver'>
              <RpcServer />
            </Route>
            <Route exact path='/admin-app-secret'>
              {
                accounts.length > 0 ? 
                (isAdmin ? <CreationForm categories={categories} type="partnership" /> : null) :
                <ConnectButton onClick={() => connectMetamask()}>
                  Connect Wallet
                </ConnectButton>
              }
            </Route>
            <Route exact path='/admin-fjcnandfyz'>
              {accounts.length > 0 ? 
                (isAdmin ? <CategoryCreation categories={categories}/> : null) :
                <ConnectButton onClick={() => connectMetamask()}>
                  Connect Wallet
                </ConnectButton>
              }
            </Route>
            <Route exact path='/admin-app-review'>
              {
                accounts.length > 0 ? 
                (isReviewer ? <Project key='/admin-app-review' pending={true} title="Pending projects"/> : null) :
                <ConnectButton onClick={() => connectMetamask()}>
                  Connect Wallet
                </ConnectButton>
              }
            </Route>
          </Switch>
        </BodyContainer>
      </PageContainer>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />

      <footer style={{paddingLeft: '10px', paddingTop: '20px', paddingRight: '10px', backgroundColor: '#121318', color: 'white', fontSize: 'small', fontStyle: "italic"}}>
        The above list of ecosystem partners is presented for informational purposes only to demonstrate the Fantom ecosystem adoption. It does not amount to an endorsement by Fantom to transact on their platform or service. Fantom takes steps to ensure its own compliance with all relevant laws and regulations. Fantom is not responsible for the actions of its ecosystem partners. The information on this website is for informational purposes only. It contains high-level summaries and is not intended to include all material information regarding the Fantom ecosystem and risk factors associated with holding FTM.
        </footer>
    </div>
  )
}

export default App
