import axios from 'axios'
import { useState } from 'react'
import { Button, Col, Form, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { PageTitle } from '../../StyledComponents'
import { constants } from '../../constants'

import './CategoryCreation.css';

const CreationBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 50px 20px;
  @media screen and (max-width: 768px) {
    padding: 20px 24px 20px;
  }
`

const RequiredMark = styled.span`
  color: #B7BECB;
`
const ButtonRow = styled(Form.Row)`
  button {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    /* white */
    color: #FFFFFF;
    outline: none;
  }
  @media screen and (max-width: 768px) {
    button {
      width: 100vh;
    }
    .btn-primary {
      width: 90% !important;
    }
  }
`

const CategoryCreation = (props) => {
  const [validated, setValidated] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const defaultErrorMsg = 'Category creation failed. Please try again.';

  const handleSubmit = async (event) => {
    setLoading(true)
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      setLoading(false);
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    try {
      let params = {
        title: event.target.title.value || '',
        category: event.target.category.value || '',
      }

      const response = await axios.post(`${constants.api_url}/add-category`, JSON.stringify(params), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setLoading(false)
      if (response.data !== undefined && response.data.success === true) {
        toast.success('Added successfully!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          window.location.reload()
        }, 500);
      } else {
        toast.error(defaultErrorMsg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setLoading(false); 
      }
    } catch (error) {
      toast.error(defaultErrorMsg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };

  return (
    <CreationBody>
      <PageTitle>
        Create a new {selectedCategory === '' ? 'category' : 'subcategory'}
      </PageTitle>
      <Form noValidate validated={validated} onSubmit={handleSubmit} id="categoryForm">
        <Form.Row>
          <Form.Group as={Col} controlId="category">
            <Form.Label>Category<RequiredMark> *</RequiredMark></Form.Label>
            <Form.Control as="select" custom defaultValue="" onChange={(e) => setSelectedCategory(e.target.value)}>
              <option value="">New Category</option>
              {
                props.categories.map((category, idx) => {
                  if (category.title !== 'All')
                    return <option key={idx} value={category.title}>{category.title}</option>
                  else
                    return null;
                })
              }
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="title">
            <Form.Label>{selectedCategory === '' ? 'Category' : 'Subcategory'} Title<RequiredMark> *</RequiredMark></Form.Label>
            <Form.Control
              type="text"
              defaultValue=""
              required
            />
          </Form.Group>
        </Form.Row>
        <ButtonRow>
          <Button variant="primary" type="submit" disabled={isLoading}>{isLoading?(<Spinner animation="grow" size="sm" />):'Submit'}</Button>
        </ButtonRow>
      </Form>
    </CreationBody>
  );
}

export default CategoryCreation
