import axios from 'axios'
import { useState, useEffect } from 'react'
import { Button, Col, Form, Spinner, Modal } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { PageTitle } from '../../StyledComponents'
import { constants } from '../../constants'
import BackIcon from '../../resource/left_arrow.svg'

import cbimage from '../../resource/cbimage.png'
import './CreationForm.css';

const CreationBody = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 50px 20px;
  @media screen and (max-width: 768px) {
    padding: 20px 24px 20px;
  }
`

const RequiredMark = styled.span`
  color: #B7BECB;
`
const IntroSpan = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #707B8F;
  float: right;
  margin-right: 5px;
  margin-top: 5px;
`

const FileButton = styled(Form.File.Input)`
  height: 88px;
  width: 88px;
  cursor: pointer;
`

const ButtonRow = styled(Form.Row)`
  display: flex;
  flex-direction: row;

  button {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    /* white */
    color: #FFFFFF;
    outline: none;
    margin: 0 10px;
    min-width: 250px;
  }
  @media screen and (max-width: 768px) {
    button {
      width: 100vh;
    }
    .btn-primary {
      width: 90% !important;
    }
  }
`

const AttachButton = styled.div({
  height: '88px',
  width: '88px',
  borderRadius: '50%',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat'
})

const BackButton = styled.div`
  position: absolute;
  user-select: none;
  padding: 30px 40px;
  cursor: pointer;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #EFF3FB;
  @media screen and (max-width: 768px) {
    position: unset;
  }
`

const BackItemImg = styled.img`
  margin-right: 12px;
  width: 12px;
`

const CreationForm = (props) => {
  let history = useHistory()
  let { id } = useParams();
  const disabled = id !== undefined;

  const [validated, setValidated] = useState(false);
  const [titleLength, setTitleLength] = useState(0);
  const [introLength, setIntroLength] = useState(0);
  const [detailsLength, setDetailsLength] = useState(0);
  const [logoImage, setLogoImg] = useState('');
  const [isLogoValid, setLogoValid] = useState(true)
  const [isLoading, setLoading] = useState(false);
  const [approving, setApproving] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [projectData, setProjectData] = useState({});
  const [show, setShow] = useState(false)
  const [reason, setReason] = useState('')
  const [logoData, setLogoData] = useState(cbimage);
  const defaultErrorMsg = 'Project creation failed. Please try again.';
  const partnershipCreatedMsg = 'Project added successfully!';
  const projectCreatedMsg = `Project added successfully! It will be listed once it's approved by the admin.`;

  const handleSubmit = async (event) => {
    setLoading(true)
    const form = event.currentTarget;
    if (logoImage === '' || form.checkValidity() === false) {
      if (logoImage === '') {
        setLogoValid(false)
      }
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      setLoading(false);
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    try {
      let params = {
        title: event.target.title.value || '',
        category: event.target.category.value || '',
        subcategory: event.target.subcategory!== undefined ? event.target.subcategory.value : '',
        shortIntro: event.target.shortIntro.value || '',
        detailIntro: event.target.detailIntro.value || '',
        website: event.target.website.value || '',
        email: event.target.email.value || '',
        twitter: event.target.twitter.value || '',
        telegram: event.target.telegram.value || '',
        discord: event.target.discord.value || '',
        github: event.target.github.value || '',
        coinmarketcap: event.target.coinmarketcap.value || '',
        defiLlama: event.target.defiLlama.value || '',
        cryptoKek: event.target.cryptoKek.value || '',
        medium: event.target.medium.value || '',
        type: props.type
      }
      if (params.website === '') {
        toast.error('Invalid website url', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setLoading(false)
        return;
      }
      if (logoImage) {
        const filePath = await pinFileToServer(logoImage, logoImage.name)
        params.logoFile = filePath;
      } else {
        params.logoFile = '';
      }

      const response = await axios.post(`${constants.api_url}/add-project`, JSON.stringify(params), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setLoading(false)
      if (response.data !== undefined && response.data.success === true) {
        toast.success(props.type === 'partnership' ? partnershipCreatedMsg : projectCreatedMsg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          history.push("/")
        }, 4000);
      } else {
        const errors = {
          'WEBSITE_NOT_REACHABLE': 'The website is not reachable.',
          'GITHUB_NOT_REACHABLE': 'The github url is not reachable.',
          'DATABASE_ERROR': 'An error occurred while adding the project.',
          'PROJECT_ALREADY_EXIST': 'The same project already exists.'
        }
        const errorMsg = response.data !== undefined && response.data.message !== undefined && response.data.message in errors ? errors[response.data.message] : defaultErrorMsg
        toast.error(errorMsg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setLoading(false); 
      }
    } catch (error) {
      toast.error(defaultErrorMsg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setLogoData(URL.createObjectURL(e.target.files[0]))
    setLogoImg(e.target.files[0])
    setLogoValid(true)
  }

  const pinFileToServer = async (fileData, filePath) => {
    const url = `${constants.api_url}/upload`;

    let data = new FormData();
    data.append('logoFile', fileData);

    try {
      const res = await axios
        .post(url, data, {
            maxBodyLength: 'Infinity', // this is needed to prevent axios from erroring out with large files
            headers: {
              'Content-Type': `multipart/form-data; boundary=${data._boundary}`
            }
        });
      if (res && res.data) {
        return res.data.logoHash;
      }
    } catch (error) {
      console.log(error)
    }
    return '';
  }

  const handleBack = () => {
    history.push('/admin-app-review');
  }

  const handleApprove = async () => {
    const params = {
      projectId: id
    }
    try {
      setLoading(true);
      setApproving(true);
      await axios.post(`${constants.api_url}/approveSubmittedProject`, JSON.stringify(params), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setLoading(false);
      setApproving(false);
      toast.success(`Project approved successfully!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        handleBack();
      }, 3000);
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeny = async () => {
    if (reason === '') {
      return;
    }
    const params = {
      projectId: id,
      reason: reason
    }
    try {
      setLoading(true)
      setApproving(false)
      await axios.post(`${constants.api_url}/denySubmittedProject`, JSON.stringify(params), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setLoading(false)
      toast.success(`Project denied and notification sent to the project owner!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      setShow(false)
      setTimeout(() => {
        handleBack();
      }, 3000);
    } catch (error) {
      console.log(error)
    }
  }

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = () => setShow(true);

  let subcats = []
  props.categories.forEach((category) => {
    if ((disabled && category.title === projectData.category && category.subcats !== undefined) || (category.title !== 'All' && category.title === selectedCategory && category.subcats !== undefined)) {
      subcats = category.subcats
    }
  });

  useEffect(() => {
    const updateProjectStatus = async () => {
      try {
        const res = await axios.get(
          `${constants.api_url}/get-project-detail?id=${id}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        setProjectData(res.data);
        setLogoData(`${constants.api_url}/logos/${res.data.logoFile}`)
      } catch (error) {
        //
        console.log(error)
      }

    }
    if (disabled) {
      updateProjectStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CreationBody>
      {
        disabled && 
        <BackButton onClick={() => handleBack()}>
          <BackItemImg src={BackIcon}></BackItemImg>
          Back
        </BackButton>
      }
      <PageTitle>
        {
          disabled ? 'Pending project' : `Submit a ${props.type  ? props.type : ''} project`
        }
      </PageTitle>
      <Form noValidate validated={validated} onSubmit={handleSubmit} id="projectForm">
        <Form.Row>
          <Form.Group as={Col} controlId="title">
            <Form.Label>
              Title
              {
                !disabled &&
                <RequiredMark> *</RequiredMark>
              }
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Project title"
              maxLength={75}
              required
              onChange={(e) => setTitleLength(e.target.value.length)}
              disabled={disabled}
              defaultValue={disabled ? projectData.title : ''}
            />
            <IntroSpan>{`${titleLength} / 75`}</IntroSpan>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="category">
            <Form.Label>
              Category
              {
                !disabled &&
                <RequiredMark> *</RequiredMark>
              }
            </Form.Label>
            <Form.Control as="select" custom defaultValue={disabled ? projectData.category : ''} placeholder="Select Category" required onChange={(e) => setSelectedCategory(e.target.value)} disabled={disabled}>
              <option value="">Select Category</option>
              {
                props.categories.map((category, idx) => {
                  if (category.title !== 'All')
                    return <option key={idx} value={category.title}>{category.title}</option>
                  else
                    return null;
                })
              }
            </Form.Control>
          </Form.Group>
        </Form.Row>
        {
          subcats.length > 0 &&
          <Form.Row>
            <Form.Group as={Col} controlId="subcategory">
              <Form.Label>Subcategory</Form.Label>
              <Form.Control as="select" custom  defaultValue={disabled ? projectData.subcategory : ''} placeholder="Select Subcategory" disabled={disabled}>
                <option value="">Select Subcategory</option>
                {
                  subcats.map((category, idx) => {
                    return <option key={idx} value={category.title}>{category.title}</option>
                  })
                }
              </Form.Control>
            </Form.Group>
          </Form.Row>
        }
        <Form.Row>
          <Form.Group as={Col} controlId="shortIntro">
            <Form.Label>
              Short Introduction
              {
                !disabled &&
                <RequiredMark> *</RequiredMark>
              }
            </Form.Label>
            <Form.Control placeholder="Enter a description for your proposal" as="textarea" maxLength={256} rows={4} onChange={(e) => setIntroLength(e.target.value.length)} required disabled={disabled} 
              defaultValue={disabled ? projectData.shortIntro : ''}/>
            <IntroSpan>{`${introLength} / 256`}</IntroSpan>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="detailIntro">
            <Form.Label>Detail Introduction</Form.Label>
            <Form.Control as="textarea" rows={3} maxLength={3000} onChange={(e) => setDetailsLength(e.target.value.length)} style={{paddingBottom: '16px'}} disabled={disabled} 
              defaultValue={disabled ? projectData.detailIntro : ''}
            />
            <IntroSpan>{`${detailsLength} / 3000`}</IntroSpan>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="logoFile">
            <Form.Label>Logo (Image Size: 288*288 px)</Form.Label>
            <AttachButton style={{backgroundImage: `url(${logoData})`}} className={isLogoValid ? 'logo-upload-button' : 'logo-upload-button is-invalid'}>
              {
                !disabled &&
                <Form.File type="file" id="logoFile" custom required>
                  <FileButton isValid accept="image/jpeg,image/gif,image/png,image/bmp" onChange={(e)=> handleFileChange(e)} />
                </Form.File>
              }
            </AttachButton>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="website">
            <Form.Label>Website</Form.Label>
            <Form.Control type="url" placeholder="https://fantom.foundation" pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" required disabled={disabled}
              defaultValue={disabled ? projectData.website : ''}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="contact@fantom.foundation" disabled={disabled}
              defaultValue={disabled ? projectData.email : ''}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="twitter">
            <Form.Label>Twitter</Form.Label>
            <Form.Control type="url" placeholder="https://twitter.com/FantomFDN" pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" disabled={disabled}
              defaultValue={disabled ? projectData.twitter : ''}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="telegram">
            <Form.Label>Telegram</Form.Label>
            <Form.Control type="url" placeholder="https://t.me/fantomfoundation" pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" disabled={disabled}
              defaultValue={disabled ? projectData.telegram : ''}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="discord">
            <Form.Label>Discord</Form.Label>
            <Form.Control type="url" placeholder="https://discord.gg/abcdef" pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" disabled={disabled}
              defaultValue={disabled ? projectData.discord : ''}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="github">
            <Form.Label>Github</Form.Label>
            <Form.Control type="url" placeholder="https://github.com/Fantom-foundation" pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" required disabled={disabled}
              defaultValue={disabled ? projectData.github : ''}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="coinmarketcap">
            <Form.Label>Coinmarketcap</Form.Label>
            <Form.Control type="url" placeholder="https://coinmarketcap.com/currencies/fantom" pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" disabled={disabled}
              defaultValue={disabled ? projectData.coinmarketcap : ''}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="defiLlama">
            <Form.Label>DefiLlama</Form.Label>
            <Form.Control type="url" placeholder="https://defillama.com/protocol/fantom" pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" disabled={disabled}
              defaultValue={disabled ? projectData.defiLlama : ''}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="cryptoKek">
            <Form.Label>CryptoKek</Form.Label>
            <Form.Control type="url" placeholder="https://kek.tools/t/0x841fad6eae12c286d1fd18d1d525dffa75c7effe/chart" pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" disabled={disabled}
              defaultValue={disabled ? projectData.cryptoKek : ''}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="medium">
            <Form.Label>Medium</Form.Label>
            <Form.Control type="url" placeholder="https://medium.com/fantomfoundation" pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" disabled={disabled}
              defaultValue={disabled ? projectData.medium : ''}
            />
          </Form.Group>
        </Form.Row>
        <ButtonRow>
          {
            disabled ? 
            <>
              <Button variant="primary" type="button" disabled={isLoading} onClick={handleApprove}>{isLoading && approving?(<Spinner animation="grow" size="sm" />):'Approve'}</Button>
              <Button variant="danger" type="button" disabled={isLoading} onClick={handleShow}>{isLoading && !approving?(<Spinner animation="grow" size="sm" />):'Deny'}</Button>
            </>
            :
            <Button variant="primary" type="submit" disabled={isLoading}>{isLoading?(<Spinner animation="grow" size="sm" />):'Submit'}</Button>
          }
        </ButtonRow>
      </Form>
      <Modal show={show} onHide={handleClose} animation={false} dialogClassName="modal-reason">
        <Modal.Header closeButton>
          <Modal.Title>Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} controlId="reason">
              <Form.Control placeholder="Enter a reason for denial" as="textarea" maxLength={256} rows={4} onChange={e => setReason(e.target.value)} required disabled={!disabled}/>
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDeny} disabled={isLoading}>
            {isLoading && !approving?(<Spinner animation="grow" size="sm" />):'Deny'}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </CreationBody>
  );
}

export default CreationForm
