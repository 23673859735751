import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Card } from 'react-bootstrap';
import { toast } from 'react-toastify'
import { PageTitle } from '../../StyledComponents'

const RPCContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
`

const RPCBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 40px 20px;
  @media screen and (max-width: 768px) {
    padding: 20px 24px 24px;
  }
`

const RPCItem = styled.div`
  width: 100%;
  padding: 16px;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #14151A;
  height: 60px;
  margin-top: 10px;
  border-radius: 8px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: unset;
    align-items: unset;
  }

  &:first-child {
    margin-top: 0;
  }
`

const RPCUrl = styled.p`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;

  /* identical to box height */

  color: #B7BECB;
  word-break: break-all;
  padding-right: 16px;
  margin: 0;
  @media screen and (max-width: 768px) {
    padding-right: 0px;
    text-align: left;
    margin-bottom: 16px;
  }
`

const RPCInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
`

const RPCCopy = styled.a`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #EFF3FB;
  cursor: pointer;
  background-color: #14151A;
  display: inline-block;
  border: 1px solid #1665EE;
  padding: 4px 16px;
  border-radius: 8px;
  margin-left: 48px;
  text-decoration: none;
  user-select: none;
  border: 1.5px solid #232934;
  :hover {
    background-color: #1969FF;
    color: #EFF3FB;
    text-decoration: none;
  }
  :focus {
    background-color: #1969FF;
    color: #EFF3FB;
    text-decoration: none;
  }

`

const RPCStatus = styled.p`
  width: 180px;
  text-align: right;
  @media screen and (max-width: 768px) {
    text-align: left;
    width: unset;
  }
  display: block;
  margin: 0;
`

const RPCStatusIcon = styled.i`
  display: block;
  padding-right: 18px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;

  /* identical to box height */
  text-align: right;

  color: #B7BECB;
  position: relative;
  white-space: nowrap;

  :after {
    background: #fc0;
    content: "";
    position: absolute;
    right: 0;
    top: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    ${props => props.primary && css`
      background: #4cd964;
    `}
  }

  @media screen and (max-width: 768px) {
    text-align: left;
    :after {
      right: 8px;
    }
  }
`

const RPCBlockHeight = styled.span`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  text-align: right;

  color: #B7BECB;
  display: block;
  word-break: break-all;
`

const initialRpcData = [
  {
    blockHeight: 0,
    ping: 0
  }, {
    blockHeight: 0,
    ping: 0
  }, {
    blockHeight: 0,
    ping: 0
  }, {
    blockHeight: 0,
    ping: 0
  }, {
    blockHeight: 0,
    ping: 0
  }, {
    blockHeight: 0,
    ping: 0
  }, {
    blockHeight: 0,
    ping: 0
  }, {
    blockHeight: 0,
    ping: 0
  }
]

const initialRpcDataTestnet = [
  {
    blockHeight: 0,
    ping: 0
  }, {
    blockHeight: 0,
    ping: 0
  }, {
    blockHeight: 0,
    ping: 0
  }, {
    blockHeight: 0,
    ping: 0
  }, {
    blockHeight: 0,
    ping: 0
  }, {
    blockHeight: 0,
    ping: 0
  }
]

const RpcServer = () => {
  const mainnetServers = [
    'https://rpcapi.fantom.network',
    'https://rpc.fantom.network',
    'https://rpc2.fantom.network',
    'https://rpc3.fantom.network',
    'https://rpc.ftm.tools',
    'https://rpc.ankr.com/fantom/',
    'https://fantom-mainnet.public.blastapi.io/',
    'https://fantom-rpc.gateway.pokt.network',
  ]

  const testnetServers = [
    'https://rpc.ankr.com/fantom_testnet/',
    'https://xapi.testnet.fantom.network/lachesis/',
    'https://fantom-testnet.public.blastapi.io/',
    'https://rpc.testnet.fantom.network', 
    'https://rpcapi.testnet.fantom.network/'
  ]

  const [serverStatus, setServerStatus] = useState(initialRpcData)
  const [serverTestnetStatus, setTestnetServerStatus] = useState(initialRpcDataTestnet)

  const fetchServer = async (serverUrl) => {
    try {
      var started = new Date().getTime()
      const res = await fetch(serverUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          "method":"eth_blockNumber","params": [],"id":1,"jsonrpc":"2.0"
        })
      })
      const result = await res.json()
      var ended = new Date().getTime()
      var milliseconds = ended - started
      return {
        blockHeight: parseInt(result.result, 16),
        ping: milliseconds
      }
    } catch (error) {
      return {
        blockHeight: 0,
        ping: 0
      }
    }
  }
  
  const copyText = (val) => {
    navigator.clipboard.writeText(val)
    toast.success('Successfully copied', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  useEffect(() => {
    const updateRPCStatus = async () => {
      try {
        const mainnetUpdates = []
        const testnetUpdates = []

        for (let i = 0; i < mainnetServers.length; i++) {
          const serverUrl = mainnetServers[i]
          mainnetUpdates.push(fetchServer(serverUrl))
        }
        const mainnetRes = await Promise.all(mainnetUpdates)
        setServerStatus(mainnetRes)

        for (let i = 0; i < testnetServers.length; i++) {
          const serverUrl = testnetServers[i]
          testnetUpdates.push(fetchServer(serverUrl))
        }
        const testnetRes = await Promise.all(testnetUpdates)
        setTestnetServerStatus(testnetRes)

      } catch (error) {
        console.log(error)
      }
  
      setTimeout(updateRPCStatus, 5000);
    }
    updateRPCStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RPCContainer>
      <RPCBody>
        <PageTitle>
          RPC Servers (Mainnet)
        </PageTitle>
        <Card>
          <Card.Body>
          {mainnetServers.map((item, index) => (
            <RPCItem key={item}>
              <RPCUrl data-v-df14d8bc="">{item}</RPCUrl>
              <RPCInfo>
                <RPCStatus>
                  <RPCStatusIcon primary={serverStatus[index].ping > 0 && serverStatus[index].ping < 200}>
                    {serverStatus[index].ping === 0 ? '?' : serverStatus[index].ping} ms 
                  </RPCStatusIcon>
                  <RPCBlockHeight>Height {serverStatus[index].blockHeight === 0 ? '?' : serverStatus[index].blockHeight}</RPCBlockHeight>
                </RPCStatus>
                <RPCCopy onClick={() => copyText(item)}>Copy</RPCCopy>
              </RPCInfo>
            </RPCItem>
          ))}
          </Card.Body>
        </Card>
      </RPCBody>
      <RPCBody>
        <PageTitle>
          RPC Servers (Testnet)
        </PageTitle>
        <Card>
          <Card.Body>
          {testnetServers.map((item, index) => (
            <RPCItem key={item}>
              <RPCUrl data-v-df14d8bc="">{item}</RPCUrl>
              <RPCInfo>
                <RPCStatus>
                  <RPCStatusIcon primary={serverTestnetStatus[index].ping > 0 && serverTestnetStatus[index].ping < 200}>
                    {serverTestnetStatus[index].ping === 0 ? '?' : serverTestnetStatus[index].ping} ms 
                  </RPCStatusIcon>
                  <RPCBlockHeight>Height {serverTestnetStatus[index].blockHeight === 0 ? '?' : serverTestnetStatus[index].blockHeight}</RPCBlockHeight>
                </RPCStatus>
                <RPCCopy onClick={() => copyText(item)}>Copy</RPCCopy>
              </RPCInfo>
            </RPCItem>
          ))}
          </Card.Body>
        </Card>
      </RPCBody>
    </RPCContainer>
  )
}

export default RpcServer
